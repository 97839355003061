var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "loadingOverlay",
    },
    [
      _c(
        "div",
        { staticClass: "loadingContainer" },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-spin loading",
            attrs: { icon: ["fas", "cog"], size: "2x" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }