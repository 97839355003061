var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.story
    ? _c("div", { staticClass: "storyViewImage" }, [
        _c("div", { staticClass: "img", style: _vm.setBackgroundImage }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }