var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "whpcList" },
    [
      _c("div", { staticClass: "pgTitle" }, [
        _vm._v("Warm, Human & Personally Crafted Stories"),
      ]),
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "addBtn", on: { click: _vm.openModal } },
            [
              _c("div", { staticClass: "txt" }, [_vm._v("add new story")]),
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: ["fas", "file-plus"] },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("LoadingOverlay", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoader,
            expression: "showLoader",
          },
        ],
      }),
      _c(
        "masonry",
        {
          key: _vm.key,
          attrs: {
            cols: { default: 7, 600: 2, 768: 3, 992: 4, 1200: 5 },
            gutter: { default: "15px" },
          },
        },
        _vm._l(this.stories, function (story, index) {
          return _c("WhpcTile", {
            key: "story" + story.id,
            attrs: { story: story, index: index },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }