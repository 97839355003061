var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "whpcSubmission" },
    [
      _c("LincolnEffortlessExperienceLogoDark"),
      _c("div", { staticClass: "form-title" }, [_vm._v("Add Story")]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("TextBox", {
            staticClass: "textBox",
            attrs: {
              label: "Subject",
              name: "subject",
              required: true,
              placeholder: "Subject *",
            },
            model: {
              value: _vm.story.subject,
              callback: function ($$v) {
                _vm.$set(_vm.story, "subject", $$v)
              },
              expression: "story.subject",
            },
          }),
          _c(
            "div",
            { staticClass: "storyBody" },
            [
              _c("RichTextBox", {
                attrs: { initialContent: _vm.story.body },
                on: { update: _vm.updateBody },
              }),
            ],
            1
          ),
          _c("ImageCropperForStory", {
            staticClass: "imagePreviewer",
            attrs: { storyData: _vm.story },
            on: { updatedImage: _vm.updatedImage },
          }),
          _c("ButtonSubmit", {
            attrs: { copy: "Send Now" },
            on: { callback: _vm.submit },
          }),
          _c("ButtonCancel", {
            staticClass: "cancel",
            on: { click: _vm.closeModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }