var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c(
      "div",
      { staticClass: "stories" },
      [!_vm.id ? _c("WhpcList") : _c("WhpcView", { attrs: { id: _vm.id } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }