var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "story" },
    [
      _c("StoryViewImage", { attrs: { story: _vm.story } }),
      _vm._m(0),
      _vm.story
        ? _c("div", { staticClass: "storyViewCopy" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.story.subject)),
            ]),
            _c("div", {
              directives: [
                { name: "openLinksInNewTab", rawName: "v-openLinksInNewTab" },
              ],
              staticClass: "article",
              domProps: { innerHTML: _vm._s(_vm.story.body) },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "divider-container" }, [
      _c("div", { staticClass: "divider" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }